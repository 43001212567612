import React from "react"
import { Link } from "gatsby"
import { useRecoilState, useRecoilValue } from "recoil"


import SEO from "../components/SEO"
import State from "../../src/utils/global/recoil.states"

const NotFoundPage = () => { 

  const theme = useRecoilValue(State.theme)

  return (
    <div className={`flex min-h-full min-w-full justify-center items-center flex-col ${theme === "dark" ? "textLight" : "textDark"}`}>
      <SEO title="404: Not found" />
      <span className="block">
          404 PAGE NOT FOUND
      </span>
      <span className="block">
          <Link to="/" >
            <span className="text-blue-400" > Click here  </span>   
          </Link>
          {" "}to go back to home... 
      </span>
    </div>
  ) }

export default NotFoundPage
